import { initMain } from "./vue/controllers/main";
import { header } from "./vue/controllers/header";
import { initIntersections } from "./features/new-lazy-load";
import proportionalScroll from "./features/proportional-scroll";
import stickyScroll from "./features/sticky-scroll";
import { galleryMixin } from "./features/gallery";
import { CarouselPlugin } from "bootstrap-vue";
import { postMixin } from "./vue/mixins/lrplus";
import mostviewed from "./features/most-viewed";
import { scrollFixedMixin } from "./features/scroll-fixed";

import SubscribeModal from "./vue/components/subscribe-modal/SubscribeModal.vue";
import FavoriteAddButton from "./vue/components/favorite-add-button/FavoriteAddButton.vue";
import InterestNews from "./vue/components/interest-news/InterestNews.vue";
import Trends from "./vue/components/trends/Trends.vue";

mostviewed();
initIntersections();
stickyScroll();
proportionalScroll();
const mixins = [galleryMixin, postMixin, scrollFixedMixin];
initMain(
  mixins,
  {
    SubscribeModal,
    FavoriteAddButton,
    InterestNews,
    Trends,
  },

  [CarouselPlugin]
);
header();
