import { changeTransform } from "./../functions/general-functions";

import { DESKTOP_QUERY } from "./../constants/constants";

const doProportionalScroll = () => {
  const a = document.getElementById("proportional-anchor-2");
  const b = document.getElementById("proportional-anchor-1");

  if (a && b) {
    let rh = 0;
    if (a.getAttribute("data-rel-id")) {
      rh = document.getElementById(a.getAttribute("data-rel-id"))?.offsetHeight
        ? document.getElementById(a.getAttribute("data-rel-id")).offsetHeight
        : 0;
    }

    const ah = a.offsetHeight + rh;
    const bh = b.offsetHeight;
    const wh = window.innerHeight;
    const diff = Math.abs(ah - bh);
    const scrollToTop = window.scrollY;

    if (ah < bh && scrollToTop != 0) {
      const j = scrollToTop - b.offsetTop + wh / 2;
      const ratio = (bh - ah) / bh;
      let c = j * ratio;
      c = Math.round(c);
      c = c < 0 ? 0 : c > diff ? diff : c;
      changeTransform(a, c);
    } else if (window.scrollY == 0) {
      changeTransform(a, 0);
    }
  }
};

const screenTest = (e) => {
  if (e.matches) {
    document.addEventListener("scroll", doProportionalScroll);
  } else {
    changeTransform(document.getElementById("proportional-anchor-2"));
    document.removeEventListener("scroll", doProportionalScroll);
  }
};

export const proportionalScroll = () => {
  document.addEventListener("lrload", function () {
    const mql = window.matchMedia(DESKTOP_QUERY);
    mql.addListener(screenTest);
    screenTest(mql);
  });
};

export default proportionalScroll;
